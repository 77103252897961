import { Box, Button, ButtonGroup, Container, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { MdCall, MdLocationOn } from 'react-icons/md'
import Lottie from 'react-lottie';
import { Options } from 'react-lottie';
import * as flowerAnimation from './animations/flower.json';
import * as headerAnimation from './animations/header.json';
import englishData from './data/english_data.json';
import tamilData from './data/tamil_data.json';
import { useState } from 'react';
import { IInvitationModel } from './models/invitationData';

function App() {

  const [invitationData, setInvitationData] = useState<IInvitationModel>(englishData as IInvitationModel);
  const [langBtnText, setLangBtnText] = useState<string>('தமிழ்');

  const flowerAnimationOptions = {
    animationData: flowerAnimation, loop: false, autoplay: true, rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  } as Options;

  const headerAnimationOptions = {
    animationData: headerAnimation, loop: false, autoplay: true, rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  } as Options;

  const contactNumbers = ["9443528950", "9443525161", "9442528950"];

  const onPhoneNumberClick = (contactNumber: string) => {
    document.location.href = `tel:${contactNumber}`
  }

  const onLocationBtnClick = (url: string) => {
    document.location.href = url;
  }

  const toggleLanguage = () => {
    if (langBtnText === 'தமிழ்') {
      setLangBtnText('English')
      setInvitationData(tamilData)
    }
    else {
      setLangBtnText('தமிழ்')
      setInvitationData(englishData)
    }
  }

  return (
    <Container height={'100vh'} display={'flex'} flexDir={'column'} alignItems={'center'} justifyContent={'center'} textAlign={'center'}>

      {/* language toggle button */}
      <Button onClick={() => toggleLanguage()} mb={'0.5rem'} alignSelf={'end'} size={'xs'}>{langBtnText}</Button>

      {/* Heading */}
      <Flex color="purple" fontSize={'1.5rem'} flexDir={'row'} alignItems={'baseline'} justifyContent={'center'}>
        <Text fontWeight={'black'}>{invitationData.groomName}</Text>
        <Text color={'black'} fontSize={'1rem'}>&nbsp;{invitationData.eventVerb}&nbsp;</Text>
        <Text fontWeight={'black'}>{invitationData.brideName}</Text>
      </Flex>

      {/* Flower animation */}
      <Lottie width={'100%'} height={'8rem'} options={headerAnimationOptions}></Lottie>

      <Tabs defaultIndex={1} align='center' mt={'1rem'} variant='soft-rounded' colorScheme='purple' size={'md'}>

        {/* Tabs list */}
        <TabList>
          {invitationData.events.map(data => {
            // For tamil font, the tab headings are rendered at 0.8 rem font size
            return <Tab fontSize={langBtnText === 'English' ? '0.8rem' : ''} key={data.id}>{data.tabTitle}</Tab>
          })}
        </TabList>

        {/* Tab content */}
        <TabPanels fontSize={'sm'}>
          {invitationData.events.map(data => {
            return <TabPanel key={data.id}>
              <Text>{invitationData.dateTimeHeading}: {data.dateAndTime}</Text>
              <Text>{invitationData.locationHeading}: {data.addressLine1}</Text>
              <Text>{data.addressLine2}</Text>
              <Button onClick={() => onLocationBtnClick(data.mapLink)} leftIcon={<MdLocationOn />} mt={'0.5rem'} size={'xs'}>{invitationData.locationBtnHeading}</Button>
            </TabPanel>
          })}

        </TabPanels>
      </Tabs>

      {/* Flower animation */}
      <Lottie width={'8rem'} height={'9rem'} options={flowerAnimationOptions}></Lottie>

      {/* footer content - contact numbers */}
      <Box mt={'0.5rem'} fontSize={'sm'}>
        <Text>{invitationData.helperText}</Text>
        <ButtonGroup mt={'0.5rem'} size={'xs'}>
          {contactNumbers.map((num, index) => {
            return <Button key={index} onClick={() => onPhoneNumberClick(num)} leftIcon={<MdCall />}>{num}</Button>
          })}
        </ButtonGroup>
      </Box>

    </Container>
  );
}

export default App;
